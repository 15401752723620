// Libs

// import 'bootstrap';

// import LazyLoad from 'vanilla-lazyload'
// import customSelect from 'custom-select'
// import GLightbox from 'glightbox'

// import tabs from './utils/_tabs'
// import modal from './widgets/_modal'
// import accordion from './widgets/_accordion'
// import pagination from './utils/_pagination'

// Widgets

import sliderCards from './components/_slider-cards'
import sliderGallery from './components/_slider-gallery'

// LazyLoad

// const lazy = new LazyLoad({
//   elements_selector: '.lazy',
//   thresholds: '30%',
// });


// Select

// customSelect('select', {
//   containerClass: 'ui-select-wrap',
//   openerClass: 'ui-select-opener',
//   panelClass: 'ui-select-panel',
//   optionClass: 'ui-select-option',
//   optgroupClass: 'ui-select-optgroup',
//   isSelectedClass: 'is-selected',
//   hasFocusClass: 'has-focus',
//   isDisabledClass: 'is-disabled',
//   isOpenClass: 'is-open'
// });

// Reset

// document.querySelectorAll('p').forEach((p) => {
//   if ( p.textContent.trim() === '' ) p.remove();
// });

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
}

window.scrollTo(0,0);

// add class to body if collapse menu is open
var collapseMenu = document.getElementById('megamenu')
if (collapseMenu) { 
  collapseMenu.addEventListener('show.bs.collapse', function () {
    document.body.classList.add('menu-opening');
  })
  collapseMenu.addEventListener('shown.bs.collapse', function () {
    document.body.classList.remove('menu-opening');
    document.body.classList.add('menu-open');
  })
  collapseMenu.addEventListener('hide.bs.collapse', function () {
    document.body.classList.remove('menu-open');
    document.body.classList.add('menu-closing');
  })
  collapseMenu.addEventListener('hidden.bs.collapse', function () {
    document.body.classList.remove('menu-closing');
    document.body.classList.remove('menu-open');
  })

}

/* Language dropdown opening */
// get all the elements with class="js-toggle-list" and add event listener click to toggle element with id = data-target attribute
document.querySelectorAll('.js-toggle-list').forEach(function(el) {
  el.addEventListener('click', function() {
    // get attribute data-target 
    this.classList.toggle('active');
    var target = this.getAttribute('data-target');
    // get element with id = data-target
    var element = document.getElementById(target);
    // toggle element with id = data-target
    // check first if element is not null
    if (element) {
      element.classList.toggle('active');
    }
  });
});

/* form filter news submit on change, for each form with class .news-filter */
document.querySelectorAll('.news-filter').forEach(function(el) {
  el.querySelectorAll('select').forEach(function(select) {
    select.addEventListener('change', function() {
      el.submit();
    });
  })
});



sliderCards();
sliderGallery();


// INIT

// Utils / Widgets

// tabs();
// modal();
// accordion();
// pagination();

// Modules

// sliderHero();
// sliderGallery();

/* apply collapse function bootstrap */
// on click on element with class js-collapse we take the href as target and add toggle the class "show" to the element with id as the href (we must remove # from the href)

// document.querySelectorAll('.js-collapse').forEach(el => {
//     el.addEventListener('click', e => {      
//       e.preventDefault();
//       let target = e.target.getAttribute('href');
//       // need to remove # from href
//       target = target.replace('#', '');
//       // check if element with id exist, if so, toggle class show
//       if (document.getElementById(target)) {
//         document.getElementById(target).classList.toggle('show');
//       }
      
//     });
//   }
// );


