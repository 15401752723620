// Components / Slider-Hero

// import 'arrive'
import Swiper from 'swiper'
import { Scrollbar, Navigation } from 'swiper/modules'

export default function sliderCards() {

  const swiperCards = document.querySelectorAll('.swiper-cards');

  if (swiperCards.length > 0) {

    swiperCards.forEach((card, index) => {

      const slider = card;
      const section = slider.closest('.section');

      const inview = parseFloat(slider.getAttribute('data-slider-inview'));
      const gap = parseInt(slider.getAttribute('data-slider-gap'));
      const autoplay = parseInt(slider.getAttribute('data-slider-play'));
      const loop = parseInt(slider.getAttribute('data-slider-loop'));

      const pagination = section.querySelector('.swiper-pagination');

      let play = false;
      let infinite = false;

      if ( autoplay ) {

        play = {
          delay: 5000,
          disableOnInteraction: true,
        };

      }

      if ( loop ) {
        infinite = true;
      }

      const init = new Swiper(slider, {

        modules: [Scrollbar, Navigation],

        loop: false,
        speed: 600,
        effect: 'slide',
        centeredSlides: false,
        keyboard: false,
        allowTouchMove: true,
        slidesPerView: inview,
        spaceBetween: gap,
        initialSlide: 0,
        autoplay: play,

        watchOverflow: true,
        watchSlidesProgress: true,
        slidesPerGroup: 1,

        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        mousewheel: true,

        // pagination: {
        //   clickable: true,
        //   el: pagination,
        //   type: 'bullets',
        // },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        breakpoints: {
        
          0: {            
            slidesPerView: 1.3
          },
          1024: {            
            slidesPerView: 1.5
          },
          1280: {            
            slidesPerView: 2.3
          },
        
        },

      });

      /* add swiper manual support on scrollbar */


    });

  };

}
