// Components / Slider-Gallery

import 'arrive'
import Swiper from 'swiper'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

Swiper.use([Navigation, Pagination, Autoplay])

export default function sliderGallery() {

  document.arrive('.swiper-gallery', {existing: true}, function() {

    const slider = this;
    const section = slider.closest('.section');

    // const inview = parseFloat(slider.getAttribute('data-slider-inview'));
    // const gap = parseInt(slider.getAttribute('data-slider-gap'));
    // const autoplay = parseInt(slider.getAttribute('data-slider-play'));
    const autoplay = false;
    // const loop = parseInt(slider.getAttribute('data-slider-loop'));
    const loop = true;

    const pagination = section.querySelector('.swiper-pagination');

    let play = false;
    let infinite = false;

    if ( autoplay ) {

      play = {
        delay: 5000,
        disableOnInteraction: true,
      };

    }

    if ( loop ) {
      infinite = true;
    }

    const init = new Swiper(slider, {

      loop: infinite,
      speed: 600,
      effect: 'slide',
      centeredSlides: false,
      keyboard: false,
      allowTouchMove: true,
      slidesPerView: 1,
      spaceBetween: 0,
      initialSlide: 0,
      autoplay: play,

      watchOverflow: true,
      watchSlidesProgress: true,
      slidesPerGroup: 1,

      pagination: {
        clickable: true,
        el: pagination,
        type: 'bullets',
      },

      navigation: true,

      // breakpoints: {
      //
      //   0: {
      //     autoplay: false,
      //     spaceBetween: gap,
      //     slidesPerView: inview
      //   },
      //
      // },

    });

  });

}
